/* rubik-300 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  //src: url('fonts/rubik-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Rubik Light'), local('Rubik-Light'),
       // url('fonts/rubik-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/rubik-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/rubik-v9-latin-300.woff') format('woff'), /* Modern Browsers */
       url('fonts/rubik-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/rubik-v9-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* arimo-regular - latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('fonts/arimo-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/arimo-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* arimo-700 - latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('fonts/arimo-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/arimo-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* arimo-italic - latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('fonts/arimo-v17-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/arimo-v17-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* arimo-700italic - latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('fonts/arimo-v17-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('fonts/arimo-v17-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
